var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-row',[(_vm.featureFlags.stats)?_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('ThreeColCard',{attrs:{"color":"background darken-2","elements":[
          {
            title:'WMT Price',
            value: _vm.tokenPrice ? '$' + _vm.tokenPrice.value : '',
            loading: _vm.loadingPrice
          },
          {
            title:'Market Cap',
            value: _vm.tokenPrice ? '$' + _vm.thousandSeparator(Math.round(_vm.tokenPrice.value * _vm.totalCirculatingSupply), ',') : '',
            loading: _vm.loading || _vm.loadingPrice
          },
          {
            title:'Total Supply',
            value: _vm.thousandSeparator(_vm.lastTotalSupply, ','),
            loading: false
          },
          {
            title:'Circulating Supply',
            value: _vm.thousandSeparator(_vm.totalCirculatingSupply, ','),
            loading: _vm.loading
          },
          {
            title:'Global Staked Balance',
            value: _vm.thousandSeparator(_vm.stakedGlobaly, ','),
            loading: _vm.loading
          } ]}})],1):_vm._e(),(_vm.featureFlags.map)?_c('v-col',{attrs:{"cols":"12"}},[_c('HeatMap',{attrs:{"title":"just a test"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('BuyBackInfo',{attrs:{"color":"background darken-2","elements":[
          {
            title:'WMT Buy-Back',
            value: '',
            link: ''
          },
          {
            title:'Address',
            value: _vm.compactString(_vm.buyBackAddress),
            link:  ''
          },
          {
            title:'Balance',
            value:  _vm.thousandSeparatorBuyBack(_vm.buyBackBalance,','),
            link:  ''
          },
          {
            title:'More Info',
            value: '',
            link:  'https://cardanoscan.io/address/01b176b69cf6157fbde00d112f88b4db2f2714b77f759b07fa941694927639c12b73c2ac9aeeaa4dd26068630abd0133426e24281dd9a2251b'
          }
        ]}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('TopConsumersTable')],1),(_vm.featureFlags.supply)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('pie-chart',{attrs:{"title":"Supply","series":_vm.circulatingPercentageSeries,"loading":_vm.loading}})],1):_vm._e(),(_vm.featureFlags.circulating)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('line-chart',{attrs:{"title":"Circulating WMT","series":_vm.supplyCirculating,"millions":true,"loading":_vm.loading}})],1):_vm._e(),(_vm.featureFlags.vaults)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('line-chart',{attrs:{"title":"Number of Vaults","series":_vm.vaultUsers,"loading":_vm.loading}})],1):_vm._e(),(_vm.featureFlags.wallets)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('line-chart',{attrs:{"title":"Number of Wallets with WMT","series":_vm.supplyWallets,"loading":_vm.loading}})],1):_vm._e(),(_vm.featureFlags.globalStake)?_c('v-col',{attrs:{"cols":"12","md":"12","lg":"4"}},[_c('line-chart',{attrs:{"title":"Global Stake Balance","series":_vm.vaultStakingBalances,"millions":true,"loading":_vm.loading}})],1):_vm._e(),(_vm.featureFlags.staking)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('line-chart',{attrs:{"title":"Wallets Staking WMT","series":_vm.vaultStakingAddresses,"loading":_vm.loading}})],1):_vm._e(),(_vm.featureFlags.rewards)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('line-chart',{attrs:{"title":"Distributed Rewards","series":_vm.vaultRewards,"millions":true,"loading":_vm.loading}})],1):_vm._e(),(_vm.featureFlags.transactions)?_c('v-col',{attrs:{"cols":"12"}},[_c('TransactionsTable')],1):_vm._e(),(_vm.featureFlags.holders)?_c('v-col',{attrs:{"cols":"12"}},[_c('TopHoldersTable')],1):_vm._e(),(_vm.featureFlags.settlements)?_c('v-col',{attrs:{"cols":"12"}},[_c('CardanoSettlements')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }