var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{staticClass:"three-col-card mx-0 pa-0 elevation-1 d-flex justify-space-evenly",class:_vm.color},_vm._l((_vm.elements),function(ref){
var title = ref.title;
var value = ref.value;
var link = ref.link;
return _c('v-col',{key:title,staticClass:"three-col-card__col",attrs:{"secondary":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-2 d-flex flex-column align-center",class:_vm.color},[_c('h3',{staticClass:"card-title text--text title text-h6 font-weight-bold text-center"},[_vm._v(" "+_vm._s(title)+" ")]),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"d-flex pa-0 align-center justify-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-nowrap text-h6 secondary--text font-weight-bold text-truncate"},[_vm._v(_vm._s(value)+" "),(link)?_c('a',{staticClass:"text-wrap table-link",attrs:{"href":link,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" CardanoScan "),_c('v-icon',{attrs:{"small":"","color":"info"}},[_vm._v("mdi-open-in-new")])],1):_vm._e()])])],1)],1)],1)],1)}),1):_c('div',{staticClass:"three-col-card__col mx-0 pa-0 d-flex flex-column"},_vm._l((_vm.elements),function(ref){
var title = ref.title;
var value = ref.value;
var link = ref.link;
return _c('v-card',{key:title,staticClass:"elevation-1 mt-1",class:_vm.color,attrs:{"flat":"","secondary":""}},[_c('v-card-text',{staticClass:"pa-2 d-flex flex-column align-center",class:_vm.color},[_c('h3',{staticClass:"card-title text--text title text-h6 font-weight-bold text-center"},[_vm._v(" "+_vm._s(title)+" ")]),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-nowrap text-h6 secondary--text font-weight-bold text-truncate"},[_vm._v(_vm._s(value)+" "),(link)?_c('a',{staticClass:"text-wrap table-link",attrs:{"href":link,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" CardanoScan "),_c('v-icon',{attrs:{"small":"","color":"info"}},[_vm._v("mdi-open-in-new")])],1):_vm._e()])])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }