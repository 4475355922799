var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ThreeColCard',{attrs:{"color":"background darken-1","elements":[
     {
      title:'Total AirNodes',
      value: _vm.totalANs,
      loading: _vm.loading
    },
    {
      title:'Total Network Consumption 24h',
      value: _vm.formattedBytes,
      loading: _vm.loading
    },
    {
      title:'Unique Users 24h',
      value: _vm.thousandSeparator(_vm.users, ','),
      loading: _vm.loading
    },
    {
      title:'Network Uptime',
      value: _vm.upTime,
      loading: _vm.loading
    } ]}})}
var staticRenderFns = []

export { render, staticRenderFns }