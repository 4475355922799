import { render, staticRenderFns } from "./NetworkInfo.vue?vue&type=template&id=0ed2948c&scoped=true&"
import script from "./NetworkInfo.vue?vue&type=script&lang=js&"
export * from "./NetworkInfo.vue?vue&type=script&lang=js&"
import style0 from "./NetworkInfo.vue?vue&type=style&index=0&id=0ed2948c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed2948c",
  null
  
)

export default component.exports