<template>
  <ThreeColCard
    color="background darken-1"
    :elements="[
       {
        title:'Total AirNodes',
        value: totalANs,
        loading
      },
      {
        title:'Total Network Consumption 24h',
        value: formattedBytes,
        loading
      },
      {
        title:'Unique Users 24h',
        value: thousandSeparator(users, ','),
        loading
      },
      {
        title:'Network Uptime',
        value: upTime,
        loading
      },
    ]"
  />
</template>

<script>
import ThreeColCard from '@/components/ThreeColCard'
import moment from 'moment'

export default {
  inject: ['nodeRepository'],
  props: ['totalAirNodes'],
  components: {
    ThreeColCard
  },
  data: () => ({
    loading: true,
    users: 0,
    bytes: 0,
    upTimestamp: '',
    now: new Date()
  }),
  async created () {
    try {
      const stats = await this.nodeRepository.getNetworkStats()
      this.upTimestamp = stats.startedAt
      this.users = stats.users
      this.bytes = stats.bytes
      this.loading = false
      this.start()
    } catch (ex) {
      this.$toast.show('Error loading data, please, try again later')
    }
  },
  computed: {
    upTime () {
      return this.fromDate(this.upTimestamp)
    },
    formattedBytes () {
      if (this.bytes === 0) return '0 Bytes'
      const k = 1024
      const dm = 2
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(this.bytes) / Math.log(k))
      const val = parseFloat((this.bytes / Math.pow(k, i)).toFixed(dm))
      return this.thousandSeparator(val, ',') + ' ' + sizes[i]
    },
    totalANs () {
      return this.totalAirNodes
    }
  },

  methods: {
    thousandSeparator: (value, char) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, char)
    },
    fromDate (value) {
      if (!value) {
        return
      }

      const now = moment.utc()
      const days = now.diff(value, 'days')
      const hours = now.diff(value, 'hours') % 24
      const minutes = now.diff(value, 'minutes') % 60

      return `${days ? days + 'd' : ''} ${hours ? this.prependZero(hours) + 'h' : ''} ${minutes ? this.prependZero(minutes) + 'm' : ''}`
    },
    start () {
      setTimeout(() => {
        this.start()
      }, 1000)
    },
    prependZero (num) {
      return num < 10 ? '0' + num : num
    }
  }
}
</script>

<style lang="scss" scoped>
.transactions-table {
  &.v-data-table--mobile {
    ::v-deep {
      table {
        display: block;
      }
      .v-data-footer {
        justify-content: flex-end;
      }
    }
  }
  ::v-deep {
    table tr:hover {
      background: transparent !important;
    }
    .v-data-footer {
      padding-top: 0.5rem;
      justify-content: flex-end;
    }
  }
  .table-link {
    text-decoration: none;
  }
}

.hover-container {
  position: relative;

  &__alt {
    position: absolute;
    z-index: 1;
    top: 0;
    transform: translateX(-40%) translateY(-20%);
    background: var(--v-background-base);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid var(--v-text-base);
  }
}

::v-deep {
  .col-md-4.col-12:last-child .text-h4 {
    @media (max-width: 767px) {
      font-size: 1.4rem !important;
    }
  }
}
</style>
