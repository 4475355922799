<template>
  <div>
    <chart-card title="Network Map">
      <v-card-text class="pa-5 pb-0">
        <div id="map" :class="{dark: this.$vuetify.theme.dark}">
          <v-progress-circular v-if="!isReady" class="loading" indeterminate color="primary" />
          <div class="legend">
            <span class="legend__item">
            <span class="legend__item text--text">
              <img :src="require('@/assets/images/air-node.svg')" aria-label="AirNode" alt="AirNode" />
              AirNodes
            </span>
            </span>
          </div>
          <div class="footer">
            <span class="footer__item">AirNode wireless network range approx 100m</span>
          </div>
        </div>
        <!-- :totalAirNodes="air_node_points.length || 0" -->
        <NetworkInfo v-if="$vuetify.breakpoint.mdAndUp" class="mt-7" :totalAirNodes="air_node_points.length > 262 ? air_node_points.length : 262"/>
      </v-card-text>
    </chart-card>
    <!-- :totalAirNodes="air_node_points.length || 0" -->
    <NetworkInfo v-if="$vuetify.breakpoint.smAndDown" :totalAirNodes="(air_node_points.length > 262 ? air_node_points.length : 262)" />
  </div>
</template>

<script>
import ChartCard from '@/components/Card'
import NetworkInfo from '@/components/NetworkInfo'
import L from 'leaflet'
import 'leaflet.markercluster'
import HeatmapOverlay from 'leaflet-heatmap'
// eslint-disable-next-line no-unused-vars
import { GestureHandling } from 'leaflet-gesture-handling'
import 'leaflet/dist/leaflet.css'
import '@/assets/cluster.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'

const nodeIcon = L.icon({
  iconUrl: require('@/assets/images/air-node.svg'),
  shadowUrl: null,
  iconSize: [4, 4]
})

/**
 * @property {NodeRepository} nodeRepository
 */
export default {
  inject: ['nodeRepository'],
  props: {
    title: String,
    dark: Boolean
  },
  data () {
    return {
      mapIdSelector: 'map',
      map: null,
      zoom: 8,
      retries: 0,
      air_node_points: [],
      darkLayer: null,
      lightLayer: null,
      heatmapLayer: null,
      fitBounds: true,
      minZoom: 2,
      minHeatMapLayerZoom: 11, // 11
      heatmapConfig: {
        // radius should be small ONLY if scaleRadius is true (or small radius is intended)
        radius: 0.0018,
        maxOpacity: 0.6,
        gradient: {
          0: '#9C56E1',
          '.5': '#9C56E1',
          '.6': '#E72775',
          1: '#E72775'
        },
        // scales the radius based on map zoom
        scaleRadius: true,
        /* if set to false the heatmap uses the global maximum for colorization
        *  if activated: uses the data maximum within the current map boundaries
        *  (there will always be a red spot with useLocalExtremas true)
        */
        useLocalExtrema: false,
        latField: 'lat',
        lngField: 'lng',
        valueField: 'count'
      }
    }
  },
  computed: {
    isReady () {
      return typeof L !== 'undefined' && this.mappedPoints.length
    },
    mappedPoints () {
      // prepare heatmap data
      let points = []
      points = points.concat(this.air_node_points.map(function (p) {
        return [
          p.latitude,
          p.longitude,
          parseFloat(p.radius)
        ]
      }))
      return points
    },
    heatmapPoints () {
      const heat = {
        max: this.air_node_points[0].radius,
        data: []
      }
      for (const node of this.air_node_points) {
        if (node.latitude && node.longitude) {
          heat.data.push(
            {
              lat: node.latitude,
              lng: node.longitude,
              count: node.radius
            }
          )
        }
      }

      return heat
    }
  },
  watch: {
    '$vuetify.theme.dark': {
      handler (value) {
        if (this.map) {
          if (value) {
            this.map.removeLayer(this.lightLayer)
            this.map.addLayer(this.darkLayer)
          } else {
            this.map.removeLayer(this.darkLayer)
            this.map.addLayer(this.lightLayer)
          }
        }
      }
    }

  },
  components: {
    ChartCard,
    NetworkInfo
  },
  methods: {
    async getNodes () {
      try {
        const response = await this.nodeRepository.getNodes()
        this.air_node_points = response.airNodes
      } catch (ex) {
        this.$toast.show('Error loading data, please, try again later')
      }
    },
    drawMap () {
      if (this.isReady) {
        try {
          // Create Layers
          this.darkLayer = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png', { crossOrigin: 'anonymous' })
          this.lightLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { crossOrigin: 'anonymous' })
          const baseLayer = this.$vuetify.theme.dark ? this.darkLayer : this.lightLayer
          this.heatmapLayer = new HeatmapOverlay(this.heatmapConfig)

          // Initialize map
          this.map = L
            .map(this.mapIdSelector, {
              gestureHandling: true,
              layers: [baseLayer, this.heatmapLayer]
            })

          // Add cluster
          const cluster = L.markerClusterGroup({
            spiderfyOnMaxZoom: false,
            disableClusteringAtZoom: 17
          }).addTo(this.map)

          // Add markers
          for (const node of this.air_node_points) {
            if (node.latitude && node.longitude) {
              L.marker([node.latitude, node.longitude], { icon: nodeIcon })
                .addTo(cluster)
            }
          }

          // Center map so all the nodes are visible
          if (this.fitBounds) {
            const mapBounds = new L.LatLngBounds(this.mappedPoints)
            this.beforeFitBounds(mapBounds)
            this.map.fitBounds(mapBounds)
          }

          // Limit the zoom out
          if (this.minZoom) {
            this.map.setMinZoom(this.minZoom)
          }

          // set Heatmap data
          this.heatmapLayer.setData(this.heatmapPoints)

          // Disable the mouse wheel
          this.map.scrollWheelZoom.disable()

          // Zoom events
          this.map.addEventListener('zoomend', this.zoomed)
        } catch (e) {
          console.log('mapError:', e)
        }
      } else {
        if (this.retries++ >= 3) {
          return
        }
        setTimeout(() => {
          /* TODO (x2) find out if there's any Ready event like in google maps */
          this.drawMap()
        }, 500)
      }
    },
    thousandSeparator: (value, char) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, char)
    },
    zoomIn () {
      this.map.setZoom(this.map._zoom + 1)
    },
    zoomOut () {
      this.map.setZoom(this.map._zoom - 1)
    },
    zoomed () {
      const isMinzoom = this.map._zoom < this.minHeatMapLayerZoom
      const radius = isMinzoom ? this.map._zoom / 4 : 0.0018
      const scaleRadius = !isMinzoom

      this.heatmapConfig.radius = radius
      this.heatmapConfig.scaleRadius = scaleRadius
    },
    beforeFitBounds (mapBounds) {
      const worldWide = Math.abs(mapBounds._northEast.lat - mapBounds._southWest.lat) > 30
      const radius = worldWide ? this.map._zoom / 4 : 0.0018
      const scaleRadius = !worldWide

      this.heatmapConfig.radius = radius
      this.heatmapConfig.scaleRadius = scaleRadius
    }
  },
  async mounted () {
    await this.$nextTick(() => {
      this.getNodes()
      this.drawMap()
    })
  },
  beforeDestroy () {
    if (this.map) {
      this.map.remove()
    }
  }
}
</script>

<style lang="scss" scoped>

  #map {
    position: relative;
    overflow: hidden;
    height: 63vh;
    min-height: 240px;
    margin: -21px -20px -28px -20px;
    width: calc(100% + 40px);
    z-index: 2;

    ::v-deep {
      .leaflet-control-attribution.leaflet-control {
        display: none;
      }
    }

    ::v-deep {
      .leaflet-marker-pane > img {
        cursor: unset !important;
      }
      .leaflet-overlay-pane > svg path {
        cursor: unset !important;
        fill: url(#heatmap-gradient);
        fill-opacity: 1;
      }
    }

    &.dark {
      ::v-deep {
        .leaflet-popup-content-wrapper, .leaflet-popup-tip {
          background-color: var(--v-background-darken2);
          border: 1px solid rgba(255,255,255,0.25);
        }

        .leaflet-bar a {
          background-color: var(--v-background-darken2);
          color: #999999;

          &:first-child {
            border-bottom-color: #666666;
          }
        }

        .leaflet-popup-close-button,
        .leaflet-popup-content {
          color: #999999;
        }

        .leaflet-popup-close-button {
          font-size: 32px;
          margin-top: -49px;
          background: var(--v-background-darken2);
          border-radius: 50%;
          padding: 6px;
          width: 40px;
          height: 40px;
          border: 1px solid rgba(255,255,255,0.25);
        }

        .leaflet-control-zoom-out.leaflet-disabled {
          cursor: 0.4;
        }
      }
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  .legend {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999999;
    padding: 2rem;
    &__item {
      display: flex;
      align-items: center;
      color: white;
      margin-bottom: 0.8rem;
      img {
        margin-right: 0.4rem;
        width: 1.6rem;
        height: 1.6rem;
      }
      &:first-child {
        img {
          width: 1.2rem;
          height: 1.2rem;
          margin-left: 0.2rem;
          margin-right: 0.6rem;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999999;
    padding: 2rem;
    &__item {
      display: flex;
      align-items: center;
      color: white;
      margin-bottom: 0.8rem;
    }
  }
  ::v-deep {
    .infowindow {
      font-size: 1.6rem;
      padding: 0.5rem;

      @media (min-width: 767px) {
        padding: 1rem;
      }

      p {
        margin: 0 0 1.2rem;

        @media (min-width: 767px) {
          margin: 0 0 0.4rem;
        }

        span {
          display: block;
          font-weight: bold;

          @media (min-width: 767px) {
            display: inline;
          }
        }
      }
    }
  }

</style>
