var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('chart-card',{attrs:{"title":'Cardano ' + _vm.cardanoScanLinksGenerator.getNetworkName() + ' Settlements'}},[_c('v-card-text',{staticClass:"pa-3 pa-md-5 pb-0"},[_c('v-data-table',{staticClass:"settlements-table background darken-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.settlements.items,"footer-props":_vm.$vuetify.breakpoint.smAndDown ? _vm.footerOptionsMobile : _vm.footerOptions,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"disable-sort":"","options":_vm.pagerOptions,"server-items-length":_vm.settlements.total},on:{"update:page":_vm.updatePage,"update:options":function($event){_vm.pagerOptions=$event}},scopedSlots:_vm._u([{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('a',{staticClass:"text-wrap table-link",attrs:{"href":_vm.cardanoScanLinksGenerator.getBlockLink(item.block),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.clickLink('block', item.block)}}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.block,20, 20))+" ")]),_c('a',{attrs:{"title":"Copy to clipboard"},on:{"click":function($event){$event.preventDefault();return _vm.copy('block', item.block)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)])]}},{key:"item.tx",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('a',{staticClass:"text-wrap table-link",attrs:{"href":_vm.cardanoScanLinksGenerator.getTransactionLink(item.tx),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.clickLink('transaction', item.tx)}}},[(_vm.$vuetify.breakpoint.xsOnly)?_c('span',{attrs:{"title":item.tx}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.tx,4, 4))+" ")]):(_vm.$vuetify.breakpoint.lgAndDown)?_c('span',{attrs:{"title":item.tx}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.tx,12, 12))+" ")]):_c('span',{attrs:{"title":item.tx}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.tx,20, 20))+" ")])]),_c('a',{attrs:{"title":"Copy to clipboard"},on:{"click":function($event){$event.preventDefault();return _vm.copy('hash', item.tx)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)])]}},{key:"item.bytes_incoming",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("thousandSeparator")(item.bytes_incoming,','))+" ")])]}},{key:"item.bytes_outgoing",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("thousandSeparator")(item.bytes_outgoing,','))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }