<template>
  <chart-card :title=" 'Cardano ' + cardanoScanLinksGenerator.getNetworkName() + ' Settlements' ">
    <v-card-text class="pa-3 pa-md-5 pb-0">
      <v-data-table
        class="settlements-table background darken-1"
        :loading="loading"
        :headers="headers"
        :items="settlements.items"
        :footer-props="$vuetify.breakpoint.smAndDown ? footerOptionsMobile : footerOptions"
        :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
        disable-sort
        @update:page="updatePage"
        :options.sync="pagerOptions"
        :server-items-length="settlements.total"
      >
        <template #item.block="{ item }">
          <div class="py-2">
            <a
              :href="cardanoScanLinksGenerator.getBlockLink(item.block)"
              target="_blank"
              rel="noopener noreferrer"
              class="text-wrap table-link"
              @click="clickLink('block', item.block)"
            >
              {{ item.block | compactString(20, 20) }}
            </a>
            <a @click.prevent="copy('block', item.block)" title="Copy to clipboard">
              <v-icon small>mdi-content-copy</v-icon>
            </a>
          </div>
        </template>
        <template #item.tx="{ item }">
          <span>
            <a
              :href="cardanoScanLinksGenerator.getTransactionLink(item.tx)"
              target="_blank"
              rel="noopener noreferrer"
              class="text-wrap table-link"
              @click="clickLink('transaction', item.tx)"
            >
              <span v-if="$vuetify.breakpoint.xsOnly" :title="item.tx">
                {{ item.tx | compactString(4, 4) }}
              </span>
              <span v-else-if="$vuetify.breakpoint.lgAndDown" :title="item.tx">
                  {{ item.tx | compactString(12, 12) }}
                </span>
              <span v-else :title="item.tx">
                  {{ item.tx | compactString(20, 20) }}
              </span>
            </a>
            <a @click.prevent="copy('hash', item.tx)" title="Copy to clipboard">
              <v-icon small>mdi-content-copy</v-icon>
            </a>
          </span>
        </template>
        <template #item.bytes_incoming="{ item }">
          <span>
            {{ item.bytes_incoming | thousandSeparator(',') }}
          </span>
        </template>
        <template #item.bytes_outgoing="{ item }">
           {{ item.bytes_outgoing | thousandSeparator(',') }}
        </template>
      </v-data-table>
    </v-card-text>
  </chart-card>
</template>

<script>
import ChartCard from './Card'
/**
 * @property {ClipboardService} clipboard
 * @property {SettlementRepository} settlementRepository
 * @property {CardanoScanLinksGenerator} cardanoScanLinksGenerator
 */
export default {
  inject: ['clipboard', 'settlementRepository', 'cardanoScanLinksGenerator'],
  components: { ChartCard },
  data: () => ({
    loading: true,
    settlements: {
      items: [],
      total: null,
      pages: null
    },
    footerOptionsMobile: {
      disableItemsPerPage: true,
      itemsPerPageOptions: [10],
      showFirstLastPage: false,
      itemsPerPageText: ''
    },
    footerOptions: {
      disableItemsPerPage: true,
      itemsPerPageOptions: [10],
      showFirstLastPage: true
    },
    pagerOptions: {},
    headers: [{
      text: 'Block',
      align: 'start',
      sortable: false,
      value: 'block'
    }, {
      text: 'Transaction',
      align: 'start',
      sortable: false,
      value: 'tx'
    }, {
      text: 'Bytes Incoming',
      align: 'end',
      sortable: false,
      value: 'bytes_incoming'
    }, {
      text: 'Bytes Outgoing',
      align: 'end',
      sortable: false,
      value: 'bytes_outgoing'
    }
    ]
  }),
  async mounted () {
    await this.updatePage(1)
  },
  filters: {
    thousandSeparator: (value, char) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, char)
    }
  },
  methods: {
    async updatePage (page = 1) {
      this.loading = true
      try {
        const response = await this.settlementRepository.getSettlementsPaginated(page)

        if (response) {
          this.settlements = response
        }
      } catch (ex) {
        this.$toast.show('Error loading data, please, try again later')
      }
      this.loading = false
    },
    copy (type, text) {
      const names = {
        block: 'Block Number',
        address: 'Address',
        hash: 'Transaction Hash'
      }
      this.clipboard.copy(text)
      this.$toast.show(names[type] + ' copied')

      if (this.$cookies.list['google-analytics']) {
        this.$gtag.event('Copy', {
          event_category: 'Settlements Table',
          event_label: text
        })
        this.$gtag.event('Copy ' + names[type], {
          event_category: 'Settlements Table',
          event_label: text
        })
      }
    },
    clickLink (type, name) {
      if (this.$cookies.list['google-analytics']) {
        this.$gtag.event('Click Link', {
          event_category: 'Settlements Table',
          event_label: name
        })
        this.$gtag.event('Click Link', {
          event_category: 'Settlements Table (' + type + ')',
          event_label: name
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.settlements-table {
  &.v-data-table--mobile {
    ::v-deep {
      table {
        display: block;
      }
      .v-data-footer {
        justify-content: flex-end;
      }

      .v-data-table__mobile-table-row {
        .v-data-table__mobile-row {
          &:first-child {
            padding-top: 5px;
          }

          &:last-child {
            padding-bottom: 12px;
          }
        }
      }

      @media (max-width: 280px) {
        .v-data-table__mobile-table-row {
          display: flex;
          flex-wrap: wrap;
          padding-block: 1rem;

          &:nth-child(2n) {
            background-color: var(--v-background-darken2);
          }

          .v-data-table__mobile-row{
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            justify-content: start;
            border-bottom: none !important;

            &__header {
              margin-top: 1rem;
            }

            &__cell {
              text-align: left;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
  ::v-deep {
    table tr:hover {
      background: transparent !important;
    }
    .v-data-footer {
      padding-top: 0.5rem;
      justify-content: flex-end;
    }
  }
  .table-link {
    text-decoration: none;
  }
}

.hover-container {
  position: relative;

  &__alt {
    position: absolute;
    z-index: 1;
    top: 0;
    transform: translateX(-40%) translateY(-20%);
    background: var(--v-background-base);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid var(--v-text-base);
  }
}
</style>
