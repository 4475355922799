<template>
  <v-card class="apex-chart-card mb-7 fill-height" color="background darken-1" elevation="1">
    <v-toolbar flat color="background darken-2 d-flex justify-space-between">
      <v-toolbar-title>{{ title }}
        <v-tooltip bottom v-if="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{tooltip}}</span>
        </v-tooltip>
      </v-toolbar-title>

      <slot name="toolbar-right" />
    </v-toolbar>
    <v-divider></v-divider>
    <div v-if="loading" class="text-center overflow-hidden py-16">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <slot v-else />
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.apex-chart-card {

  &.v-sheet.v-card {
    border-radius: 5px;
  }

  .apexcharts-toolbar {
    z-index: 1;
  }

  .apexcharts-menu {
    background-color: var(--v-background-darken2);

    .apexcharts-menu-item:hover {
      background-color: var(--v-background-base);
    }
  }

  .v-toolbar__content {
    width: 100%;
    justify-content: space-between;
  }

  .apexcharts-legend-text {
    color: var(--v-text-base) !important;
  }

  a {
    color: var(--v-info-base);
  }

  .apexcharts-canvas {
    margin: 0 auto;
  }
}
</style>
