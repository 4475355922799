var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('chart-card',{attrs:{"title":"Top 10 AirNodes by revenue in Zanzibar"}},[_c('v-card-text',{staticClass:"pa-3 pa-md-5 pb-0"},[_c('v-data-table',{staticClass:"top-airnodes-table background darken-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.airnodes,"footer-props":_vm.$vuetify.breakpoint.smAndDown ? _vm.footerOptionsMobile : _vm.footerOptions,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"disable-sort":"","hide-default-footer":"","hide-default-header":_vm.$vuetify.breakpoint.smAndUp,"options":_vm.pagerOptions},on:{"update:page":_vm.updatePage,"update:options":function($event){_vm.pagerOptions=$event}},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndUp)?{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h,index){return _c('th',{key:index,class:("text-" + (h.align))},[_c('span',{attrs:{"text-align":"center"}},[_vm._v(_vm._s(h.text))]),(h.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toClick(on, $event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('span',{attrs:{"text-align":"center"}},[_vm._v(_vm._s(h.tooltip)+" ")])]):_vm._e()],1)}),0)])]}}:(_vm.$vuetify.breakpoint.smAndDown)?{key:"header",fn:function(ref){return [_c('thead',[_c('tr',[_c('th',{class:"text-start"},[_c('span',{attrs:{"text-align":"center"}},[_vm._v("AirNode Host Revenue")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toClick(on, $event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('span',{attrs:{"text-align":"center"}},[_vm._v("Estimated monthly revenue for the AirNode Host ")])])],1)])])]}}:null,{key:"item.hashed_did",fn:function(ref){
var item = ref.item;
return [_c('span',[(_vm.$vuetify.breakpoint.width < 480)?_c('span',[_vm._v(" "+_vm._s(_vm._f("compactString")(item.hashed_did,6, 6))+" ")]):(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_vm._v(" "+_vm._s(_vm._f("compactString")(item.hashed_did,8, 8))+" ")]):(_vm.$vuetify.breakpoint.lgAndDown)?_c('span',[_vm._v(" "+_vm._s(_vm._f("compactString")(item.hashed_did,12, 12))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.hashed_did)+" ")]),_c('a',{attrs:{"title":"Copy to clipboard"},on:{"click":function($event){$event.preventDefault();return _vm.copy(item.hashed_did)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)])]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.humanFileSize(item.usage))+" ")]}},{key:"item.revenue",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"m-0",attrs:{"color":"primary","label":"","small":""}},[_vm._v(_vm._s(item.revenue))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }