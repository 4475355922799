<template>
  <div>
    <v-row v-if="$vuetify.breakpoint.mdAndUp" :class="color"  class="three-col-card mx-0 pa-0 elevation-1 d-flex justify-space-evenly">
      <v-col  class="three-col-card__col" secondary v-for="{title, value, loading} in elements" :key="title">
        <v-card flat>
          <v-card-text class="pa-2 d-flex flex-column align-center" :class="color">
              <h3 class="card-title text--text title text-h6 font-weight-bold text-center">
                {{ title }}
              </h3>
            <div v-if="loading" class="text-center overflow-hidden py-8">
              <v-progress-circular indeterminate :color=" color === 'primary' ? 'accent' : 'primary' " />
            </div>
            <v-row v-else class="mt-1">
              <v-col cols="12" class="d-flex pa-0 align-center justify-center">
                <p class="text-nowrap text-h6 secondary--text font-weight-bold text-truncate">{{ value }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div v-else   class="three-col-card__col mx-0 pa-0  d-flex flex-column">
        <v-card :class="color" flat class="elevation-1 mt-1" secondary v-for="{title, value, loading} in elements" :key="title">
          <v-card-text class="pa-2 d-flex flex-column align-center" :class="color">
              <h3 class="card-title text--text title text-h6 font-weight-bold text-center">
                {{ title }}
              </h3>
            <div v-if="loading" class="text-center overflow-hidden py-8">
              <v-progress-circular indeterminate :color=" color === 'primary' ? 'accent' : 'primary' " />
            </div>
            <v-row class="mt-1">
              <v-col cols="12" class="d-flex align-center justify-center">
                <p class="text-nowrap text-h6 secondary--text font-weight-bold text-truncate">{{ value }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    elements: {
      type: Array,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  data: () => ({
    elementVisible: false
  }),
  created () {
    setTimeout(() => (this.elementVisible = true), 10)
  }
}
</script>

<style lang="scss" scoped>
.three-col-card {
  border-radius: 5px;
  align-items: center;

  &__col {
    flex: 1;
  }
}
</style>
