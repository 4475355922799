<template>
  <chart-card :title="title" :loading="loading">
    <div class="mt-4 pa-4">
      <vue-apex-charts type="line" height="350" :options="options" :series="compSeries" />
    </div>
  </chart-card>
</template>

<script>
import { background, baseColors, border, graphColors } from '@/assets/colors.js'
import VueApexCharts from 'vue-apexcharts'
import ChartCard from '../Card'

function thousandSeparator (value, char = ',') {
  const int = value.toString().split('.')[0]
  const dec = value.toString().split('.')[1]

  return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, char) + (dec ? ('.' + dec) : '')
}

function thousandtoK (value) {
  if (value < 1000) {
    return value
  }
  return (value / 1000) + ' K'
}

export default {
  components: { ChartCard, VueApexCharts },
  props: {
    title: {
      type: String,
      required: true
    },
    series: {
      type: Object,
      required: true
    },
    millions: {
      type: Boolean,
      required: false,
      default: false
    },
    units: {
      type: String,
      required: false,
      default: ''
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    suffix: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onClick (event, _this) {
      if (_this.$cookies.list['google-analytics']) {
        if (event.target.classList.contains('apexcharts-menu-item')) {
          let type = ''
          const days = _this.$store.state.days + ' ' + 'days'

          if (event.target.classList.contains('exportSVG')) {
            type = 'SVG'
          } else if (event.target.classList.contains('exportPNG')) {
            type = 'PNG'
          } else if (event.target.classList.contains('exportCSV')) {
            type = 'CSV'
          }

          _this.$gtag.event('Download chart (by period)', {
            event_category: 'Chart',
            event_label: days
          })

          _this.$gtag.event('Download chart (by name)', {
            event_category: 'Chart',
            event_label: _this.title
          })

          _this.$gtag.event('Download chart (by type)', {
            event_category: 'Chart',
            event_label: type
          })

          _this.$gtag.event('Download chart (count)', {
            event_category: 'Chart',
            event_label: 'total'
          })

          _this.$gtag.event('Download chart (by type and period)', {
            event_category: 'Chart',
            event_label: type + ' ' + days
          })

          _this.$gtag.event('Download chart (by name and period)', {
            event_category: 'Chart',
            event_label: _this.title + ' ' + days
          })

          _this.$gtag.event('Download chart (by name and type)', {
            event_category: 'Chart',
            event_label: _this.title + ' ' + type
          })

          _this.$gtag.event('Download chart (by name, type and period)', {
            event_category: 'Chart',
            event_label: _this.title + ' ' + type + ' ' + days
          })
        }
      }
    }
  },
  computed: {
    compSeries () {
      return [{
        name: this.title,
        data: Object.values(this.series)
      }]
    },
    options () {
      const component = this

      return {
        colors: graphColors,
        chart: {
          foreColor: this.$vuetify.theme.dark ? baseColors.white : baseColors.black,
          background: this.$vuetify.theme.dark ? background.dark.darken1 : background.light.darken1,
          height: 350,
          zoom: {
            enabled: false
          },
          events: {
            click: (event) => this.onClick(event, this)
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'left'
        },
        grid: {
          row: {
            opacity: 0.5
          },
          borderColor: border.dark.transparent
        },
        xaxis: {
          categories: this.series ? Object.keys(this.series) : [],
          labels: {
            style: {
              cssClass: 'text--text text--darken-1 fill-color'
            }
          },
          type: 'datetime'
        },
        yaxis: {
          min: 0,
          forceNiceScale: true,
          title: {
            text: component.units
          },
          tooltip: {
            enabled: false
          },
          labels: {
            style: {
              cssClass: 'text--text text--darken-1 fill-color'
            },
            formatter: function (y) {
              if (!component.millions) {
                return thousandtoK(y)
              }
              if (typeof y === 'number') {
                return thousandSeparator(y / 1000000 + ' M')
              }
              return thousandtoK(y)
            }
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: function (y) {
              if (!component.millions) {
                return component.prefix + thousandSeparator(y) + component.suffix
              }
              if (typeof y === 'number') {
                return (y / 1000000).toFixed(2) + ' Millions'
              }
              return component.prefix + thousandSeparator(y) + component.suffix
            }
          }
        }
      }
    }
  }
}
</script>
