var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('chart-card',{attrs:{"title":"Top WMT Holders"}},[_c('v-card-text',{staticClass:"pa-3 pa-md-5 pb-0"},[_c('v-data-table',{staticClass:"top-holders-table background darken-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.holders,"footer-props":_vm.$vuetify.breakpoint.smAndDown ? _vm.footerOptionsMobile : _vm.footerOptions,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"disable-sort":"","options":_vm.pagerOptions,"server-items-length":100},on:{"update:page":_vm.updatePage,"update:options":function($event){_vm.pagerOptions=$event}},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',[(_vm.$vuetify.breakpoint.width < 480)?_c('span',[_vm._v(" "+_vm._s(_vm._f("compactString")(item.address,6, 6))+" ")]):(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_vm._v(" "+_vm._s(_vm._f("compactString")(item.address,8, 8))+" ")]):(_vm.$vuetify.breakpoint.lgAndDown)?_c('span',[_vm._v(" "+_vm._s(_vm._f("compactString")(item.address,12, 12))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.address)+" ")]),_c('a',{attrs:{"title":"Copy to clipboard"},on:{"click":function($event){$event.preventDefault();return _vm.copy(item.address)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"m-0",attrs:{"label":"","small":""}},[_vm._v(_vm._s(_vm._f("thousandSeparator")(item.balance))+" WMT")])]}},{key:"item.share",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"m-0",attrs:{"label":"","small":""}},[_vm._v(_vm._s((item.share * 100).toFixed(2))+"%")])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-wrap table-link",attrs:{"href":_vm.cardanoScanLinksGenerator.getAddressLink(item.address, 'mainnet'),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.clickLink(item.address)}}},[_vm._v(" View "),_c('span',{attrs:{"hidden":_vm.$vuetify.breakpoint.mdOnly}},[_vm._v(" more ")]),_c('v-icon',{attrs:{"small":"","color":"info"}},[_vm._v("mdi-open-in-new")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }