var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('chart-card',{attrs:{"title":"Latest Transactions"}},[_c('v-card-text',{staticClass:"pa-3 pa-md-5 pb-0"},[_c('v-data-table',{staticClass:"transactions-table background darken-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.transactions,"footer-props":_vm.$vuetify.breakpoint.smAndDown ? _vm.footerOptionsMobile : _vm.footerOptions,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"disable-sort":"","options":_vm.pagerOptions,"server-items-length":100},on:{"update:page":_vm.updatePage,"update:options":function($event){_vm.pagerOptions=$event}},scopedSlots:_vm._u([{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2 transactions-table__block"},[_c('a',{staticClass:"text-wrap table-link",attrs:{"href":_vm.cardanoScanLinksGenerator.getBlockLink(item.block, 'mainnet'),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.clickLink('block', item.block)}}},[_vm._v(" "+_vm._s(item.block)+" ")]),_c('a',{attrs:{"title":"Copy to clipboard"},on:{"click":function($event){$event.preventDefault();return _vm.copy('block', item.block)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1),_c('br'),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.time.format('YYYY-MM-DD')))]),(!_vm.$vuetify.breakpoint.smAndDown)?_c('br'):_c('span',[_vm._v(" | ")]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.time.format('HH:mm:ss'))+" UTC")])])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('a',{staticClass:"text-wrap table-link",attrs:{"href":_vm.cardanoScanLinksGenerator.getAddressLink(item.address, 'mainnet'),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.clickLink('address', item.address)}}},[(_vm.$vuetify.breakpoint.xsOnly)?_c('span',{attrs:{"title":item.address}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.address,4, 4))+" ")]):(_vm.$vuetify.breakpoint.lgAndDown)?_c('span',{attrs:{"title":item.address}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.address,12, 12))+" ")]):_c('span',{attrs:{"title":item.address}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.address,32, 32))+" ")])]),_c('a',{attrs:{"title":"Copy to clipboard"},on:{"click":function($event){$event.preventDefault();return _vm.copy('address', item.address)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)])]}},{key:"item.hash",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('a',{staticClass:"text-wrap table-link",attrs:{"href":_vm.cardanoScanLinksGenerator.getTransactionLink(item.hash, 'mainnet'),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.clickLink('transaction', item.hash)}}},[(_vm.$vuetify.breakpoint.xsOnly)?_c('span',{attrs:{"title":item.hash}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.hash,4, 4))+" ")]):(_vm.$vuetify.breakpoint.lgAndDown)?_c('span',{attrs:{"title":item.address}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.address,12, 12))+" ")]):_c('span',{attrs:{"title":item.hash}},[_vm._v(" "+_vm._s(_vm._f("compactString")(item.hash,20, 20))+" ")])]),_c('a',{attrs:{"title":"Copy to clipboard"},on:{"click":function($event){$event.preventDefault();return _vm.copy('hash', item.hash)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)])]}},{key:"item.wmt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"m-0",attrs:{"label":"","small":!_vm.$vuetify.breakpoint.smAndDown}},[_vm._v(_vm._s(_vm._f("thousandSeparator")(item.wmt))+" WMT")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }