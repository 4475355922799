<!-- eslint-disable vue/require-v-for-key -->
<template>
  <chart-card title="Top 10 AirNodes by revenue in Zanzibar">
    <v-card-text class="pa-3 pa-md-5 pb-0">

      <v-data-table :loading="loading" :headers="headers" :items="airnodes" class="top-airnodes-table background darken-1 "
        :footer-props="$vuetify.breakpoint.smAndDown ? footerOptionsMobile : footerOptions"
        :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm" disable-sort @update:page="updatePage" hide-default-footer :hide-default-header="$vuetify.breakpoint.smAndUp"
        :options.sync="pagerOptions">

        <template v-if="$vuetify.breakpoint.smAndUp" v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th :class="`text-${h.align}`" v-for="(h,index) in headers" v-bind:key="index">
                <span  text-align="center">{{h.text}}</span>
                <v-tooltip  v-if="h.tooltip" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" icon @click="toClick(on, $event)">
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                  </template>
                  <span text-align="center">{{ h.tooltip}}

                  </span>
                </v-tooltip>
              </th>
            </tr>
          </thead>
        </template>

        <template v-else-if="$vuetify.breakpoint.smAndDown" v-slot:header="{ }">
          <thead>
            <tr>
              <th :class="`text-start`">
                <span  text-align="center">AirNode Host Revenue</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" icon @click="toClick(on, $event)">
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                  </template>
                  <span text-align="center">Estimated monthly revenue for the AirNode Host

                  </span>
                </v-tooltip>
              </th>
            </tr>
          </thead>
        </template>

        <template #item.hashed_did="{ item }">
          <span>
            <span v-if="$vuetify.breakpoint.width < 480">
              {{ item.hashed_did | compactString(6, 6) }}
            </span>
            <span v-else-if="$vuetify.breakpoint.xsOnly">
              {{ item.hashed_did | compactString(8, 8) }}
            </span>
            <span v-else-if="$vuetify.breakpoint.lgAndDown">
              {{ item.hashed_did | compactString(12, 12) }}
            </span>
            <span v-else>
              {{ item.hashed_did }}
            </span>
            <a @click.prevent="copy(item.hashed_did)" title="Copy to clipboard">
              <v-icon small>mdi-content-copy</v-icon>
            </a>
          </span>
        </template>
        <template #item.usage="{ item }">
          {{ humanFileSize(item.usage) }}
        </template>
        <template #item.revenue="{ item }">
          <v-chip class="m-0" color="primary" label small>{{ item.revenue }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </chart-card>
</template>

<script>
import ChartCard from './Card'
import HttpError from '@/errors/httpError'

/**
 * @property {ClipboardService} clipboard
 * @property {HttpClient} httpClient
 */
export default {
  inject: ['clipboard', 'httpClient'],
  components: { ChartCard },
  data: () => ({
    loading: true,
    airnodes: [],
    footerOptionsMobile: {
      disableItemsPerPage: true,
      itemsPerPageOptions: [10],
      showFirstLastPage: false,
      itemsPerPageText: ''
    },
    footerOptions: {
      disableItemsPerPage: true,
      itemsPerPageOptions: [10],
      showFirstLastPage: true
    },
    pagerOptions: {},
    headers: [{
      text: 'AirNode',
      align: 'start',
      sortable: false,
      value: 'hashed_did'
    }, {
      text: 'Consumption last 24hr',
      align: 'center',
      sortable: false,
      value: 'usage'
    }, {
      text: 'AirNode Host Revenue',
      align: 'center',
      sortable: false,
      value: 'revenue',
      tooltip: 'Estimated monthly revenue for the AirNode Host'
    }]
  }),
  async mounted () {
    await this.updatePage()
  },
  methods: {
    toClick ({ mouseenter, mouseleave }, e) {
      clearTimeout(this._timerId)
      mouseenter(e)
      this._timerId = setTimeout(() => mouseleave(e), 1000)
    },
    compareRevenue (a, z) {
      // revenue is a string. like $199.00 or $29.00 the don't sort lexically
      const aa = parseFloat(a.revenue.replace(/[^0-9.]/g, ''))
      const zz = parseFloat(z.revenue.replace(/[^0-9.]/g, ''))
      // descending
      return zz - aa
    },
    async updatePage () {
      this.loading = true
      try {
        const response = await this.httpClient.get('/json/air-nodes/revenue')
        if (!response || !response.ok) {
          throw new HttpError(response.status, response)
        }
        this.airnodes = await response.json()
        this.airnodes = this.airnodes.sort(this.compareRevenue)
        // console.log(this.airnodes)
      } catch (ex) {
        this.$toast.show('Error loading data, please, try again later')
      }
      this.loading = false
    },
    copy (text) {
      this.clipboard.copy(text)
      this.$toast.show('Address copied')

      if (this.$cookies.list['google-analytics']) {
        this.$gtag.event('Copy Address', {
          event_category: 'Top air nodes Table',
          event_label: text
        })
      }
    },
    humanFileSize (input, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024
      let bytes = Number(input)
      if (isNaN(bytes)) {
        return 'error'
      }
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }

      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

      return bytes.toFixed(dp) + ' ' + units[u]
    }
  }
}
</script>

<style lang="scss" scoped>

.top-airnodes-table {
  &.v-data-table--mobile {
    ::v-deep {
      table {
        display: block;
      }

      .v-data-footer {
        justify-content: flex-end;
      }

      .v-data-table__mobile-table-row {
        .v-data-table__mobile-row {
          &:first-child {
            padding-top: 5px;
          }

          &:last-child {
            padding-bottom: 12px;
          }
        }
      }

      @media (max-width: 280px) {
        .v-data-table__mobile-table-row {
          display: flex;
          flex-wrap: wrap;
          padding-block: 1rem;

          &:nth-child(2n) {
            background-color: var(--v-background-darken2);
          }

          .v-data-table__mobile-row {
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            justify-content: start;
            border-bottom: none !important;

            &__header {
              margin-top: 1rem;
            }

            &__cell {
              text-align: left;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }

  ::v-deep {
    table tr:hover {
      background: transparent !important;
    }

    .v-data-footer {
      padding-top: 0.5rem;
      justify-content: flex-end;
    }
  }

  .table-link {
    text-decoration: none;
  }
}

.hover-container {
  position: relative;

  &__alt {
    position: absolute;
    z-index: 1;
    top: 0;
    transform: translateX(-40%) translateY(-20%);
    background: var(--v-background-base);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid var(--v-text-base);
  }
}
</style>
