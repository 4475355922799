<template>
  <chart-card title="Top WMT Holders">
    <v-card-text class="pa-3 pa-md-5 pb-0">
      <v-data-table
        class="top-holders-table background darken-1"
        :loading="loading"
        :headers="headers"
        :items="holders"
        :footer-props="$vuetify.breakpoint.smAndDown ? footerOptionsMobile : footerOptions"
        :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
        disable-sort
        @update:page="updatePage"
        :options.sync="pagerOptions"
        :server-items-length="100"
      >
        <template #item.address="{ item }">
          <span>
            <span v-if="$vuetify.breakpoint.width < 480">
              {{ item.address | compactString(6, 6) }}
            </span>
            <span v-else-if="$vuetify.breakpoint.xsOnly">
              {{ item.address | compactString(8, 8) }}
            </span>
            <span v-else-if="$vuetify.breakpoint.lgAndDown">
              {{ item.address | compactString(12, 12) }}
            </span>
            <span v-else>
              {{ item.address }}
            </span>
            <a @click.prevent="copy(item.address)" title="Copy to clipboard">
              <v-icon small>mdi-content-copy</v-icon>
            </a>
          </span>
        </template>
        <template #item.balance="{ item }">
          <v-chip class="m-0" label small>{{ item.balance | thousandSeparator }} WMT</v-chip>
        </template>
        <template #item.share="{ item }">
          <v-chip class="m-0" label small>{{ (item.share * 100).toFixed(2) }}%</v-chip>
        </template>
        <template #item.link="{ item }">
          <a :href="cardanoScanLinksGenerator.getAddressLink(item.address, 'mainnet')" target="_blank" rel="noopener noreferrer"
             class="text-wrap table-link" @click="clickLink(item.address)">
            View <span :hidden="$vuetify.breakpoint.mdOnly"> more </span><v-icon small color="info">mdi-open-in-new</v-icon>
          </a>
        </template>
      </v-data-table>
    </v-card-text>
  </chart-card>
</template>

<script>
import ChartCard from './Card'

/**
 * @property {ClipboardService} clipboard
 * @property {HolderRepository} holderRepository
 * @property {CardanoScanLinksGenerator} cardanoScanLinksGenerator
 */
export default {
  inject: ['clipboard', 'holderRepository', 'cardanoScanLinksGenerator'],
  components: { ChartCard },
  data: () => ({
    loading: true,
    holders: [],
    footerOptionsMobile: {
      disableItemsPerPage: true,
      itemsPerPageOptions: [10],
      showFirstLastPage: false,
      itemsPerPageText: ''
    },
    footerOptions: {
      disableItemsPerPage: true,
      itemsPerPageOptions: [10],
      showFirstLastPage: true
    },
    pagerOptions: {},
    headers: [{
      text: 'Address',
      align: 'start',
      sortable: false,
      value: 'address'
    }, {
      text: 'Amount',
      align: 'center',
      sortable: false,
      value: 'balance'
    }, {
      text: 'Share',
      align: 'center',
      sortable: false,
      value: 'share'
    }, {
      text: '+ Info',
      align: 'end',
      sortable: false,
      value: 'link'
    }]
  }),
  async mounted () {
    await this.updatePage(1)
  },
  methods: {
    async updatePage (page = 1) {
      this.loading = true
      try {
        this.holders = await this.holderRepository.getHoldersPaginated(page)
      } catch (ex) {
        this.$toast.show('Error loading data, please, try again later')
      }
      this.loading = false
    },
    copy (text) {
      this.clipboard.copy(text)
      this.$toast.show('Address copied')

      if (this.$cookies.list['google-analytics']) {
        this.$gtag.event('Copy Address', {
          event_category: 'Top holders Table',
          event_label: text
        })
      }
    },
    clickLink (name) {
      if (this.$cookies.list['google-analytics']) {
        this.$gtag.event('Click Link', {
          event_category: 'Top holders Table',
          event_label: name
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-holders-table {
  &.v-data-table--mobile {
    ::v-deep {
      table {
        display: block;
      }
      .v-data-footer {
        justify-content: flex-end;
      }

      .v-data-table__mobile-table-row {
        .v-data-table__mobile-row {
          &:first-child {
            padding-top: 5px;
          }

          &:last-child {
            padding-bottom: 12px;
          }
        }
      }

      @media (max-width: 280px) {
        .v-data-table__mobile-table-row {
          display: flex;
          flex-wrap: wrap;
          padding-block: 1rem;

          &:nth-child(2n) {
            background-color: var(--v-background-darken2);
          }

          .v-data-table__mobile-row{
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            justify-content: start;
            border-bottom: none !important;

            &__header {
              margin-top: 1rem;
            }

            &__cell {
              text-align: left;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
  ::v-deep {
    table tr:hover {
      background: transparent !important;
    }
    .v-data-footer {
      padding-top: 0.5rem;
      justify-content: flex-end;
    }
  }
  .table-link {
    text-decoration: none;
  }
}

.hover-container {
  position: relative;

  &__alt {
    position: absolute;
    z-index: 1;
    top: 0;
    transform: translateX(-40%) translateY(-20%);
    background: var(--v-background-base);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid var(--v-text-base);
  }
}
</style>
