<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown" class="down-top-padding">
    <v-row>
      <v-col v-if="featureFlags.stats" cols="12" sm="12" lg="12">
        <ThreeColCard
          color="background darken-2"
          :elements="[
            {
              title:'WMT Price',
              value: tokenPrice ? '$' + tokenPrice.value : '',
              loading: loadingPrice
            },
            {
              title:'Market Cap',
              value: tokenPrice ? '$' + thousandSeparator(Math.round(tokenPrice.value * totalCirculatingSupply), ',') : '',
              loading: loading || loadingPrice
            },
            {
              title:'Total Supply',
              value: thousandSeparator(lastTotalSupply, ','),
              loading: false
            },
            {
              title:'Circulating Supply',
              value: thousandSeparator(totalCirculatingSupply, ','),
              loading: loading
            },
            {
              title:'Global Staked Balance',
              value: thousandSeparator(stakedGlobaly, ','),
              loading: loading
            },
          ]"
        />
      </v-col>
      <!-- <v-col cols="12">
        <line-chart title="WMT Price" :series="tokenHistoricPrice" :loading="loadingHistoricPrice" units="USD $" prefix="$" />
      </v-col> -->
      <v-col v-if="featureFlags.map" cols="12">
        <HeatMap title="just a test" />
      </v-col>
      <v-col cols="12">
          <BuyBackInfo
            color="background darken-2"
            :elements="[
            {
              title:'WMT Buy-Back',
              value: '',
              link: ''
            },
            {
              title:'Address',
              value: compactString(buyBackAddress),
              link:  ''
            },
            {
              title:'Balance',
              value:  thousandSeparatorBuyBack(buyBackBalance,','),
              link:  ''
            },
            {
              title:'More Info',
              value: '',
              link:  'https://cardanoscan.io/address/01b176b69cf6157fbde00d112f88b4db2f2714b77f759b07fa941694927639c12b73c2ac9aeeaa4dd26068630abd0133426e24281dd9a2251b'
            }
          ]"
          />
      </v-col>
      <v-col cols="12">
        <TopConsumersTable />
      </v-col>
      <v-col v-if="featureFlags.supply" cols="12" md="6" lg="6">
        <pie-chart title="Supply" :series="circulatingPercentageSeries" :loading="loading"></pie-chart>
      </v-col>
      <v-col v-if="featureFlags.circulating" cols="12" md="6" lg="6">
        <line-chart title="Circulating WMT" :series="supplyCirculating" :millions="true" :loading="loading" />
      </v-col>
      <!--<v-col cols="12" md="6" lg="4">
        <column-chart title="Circulating VS Total WMT" :series="circulatingSupplyBars" :millions="true" units="WMT (Millions)" :loading="loading" />
      </v-col>-->
      <v-col v-if="featureFlags.vaults" cols="12" md="6">
        <line-chart title="Number of Vaults" :series="vaultUsers" :loading="loading" />
      </v-col>
      <v-col v-if="featureFlags.wallets" cols="12" md="6" lg="6">
        <line-chart title="Number of Wallets with WMT" :series="supplyWallets" :loading="loading" />
      </v-col>
      <v-col v-if="featureFlags.globalStake" cols="12" md="12" lg="4">
        <line-chart title="Global Stake Balance" :series="vaultStakingBalances" :millions="true" :loading="loading" />
      </v-col>
      <v-col v-if="featureFlags.staking" cols="12" md="6" lg="4">
        <line-chart title="Wallets Staking WMT" :series="vaultStakingAddresses" :loading="loading" />
      </v-col>
      <!-- <v-col cols="12" md="6" lg="4">
        <line-chart title="Total Number of Earth Node reservations" :series="vaultEarthNodes" :loading="loading" />
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <line-chart title="Addresses with Earth Nodes" :series="vaultAddressesWithEarthNode" :loading="loading" />
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <line-chart title="Average of addresses with Earth Node" :series="vaultAvgEarthNodesPerWallet" :loading="loading" />
      </v-col> -->
      <v-col v-if="featureFlags.rewards" cols="12" md="6" lg="4">
        <line-chart title="Distributed Rewards" :series="vaultRewards" :millions="true" :loading="loading" />
      </v-col>
      <v-col v-if="featureFlags.transactions" cols="12">
        <TransactionsTable />
      </v-col>
      <v-col v-if="featureFlags.holders" cols="12">
        <TopHoldersTable />
      </v-col>
      <v-col v-if="featureFlags.settlements" cols="12">
        <CardanoSettlements />
      </v-col>
      <!--
      <v-col cols="12" md="6" lg="4">
        <line-chart title="Number of addresses with WMT" :series="supplyAddresses" :loading="loading" />
      </v-col>
      -->
    </v-row>
  </v-container>
</template>

<script>
import CardanoSettlements from '@/components/CardanoSettlements'
import LineChart from '@/components/charts/Line'
import PieChart from '@/components/charts/Pie'
import HeatMap from '@/components/HeatMap'
import ThreeColCard from '@/components/ThreeColCard'
import BuyBackInfo from '@/components/BuyBackInfo'
import TopHoldersTable from '@/components/TopHoldersTable'
import TransactionsTable from '@/components/TransactionsTable'
import TopConsumersTable from '@/components/TopConsumersTable'
import moment from 'moment'
import FormatterService from '@/services/formatter'
/**
 * @property {DashboardRepository} dashboardRepository
 * @property {PriceRepository} priceRepository
 */
export default {
  inject: ['dashboardRepository', 'priceRepository', 'holderRepository'],
  components: {
    LineChart,
    PieChart,
    ThreeColCard,
    BuyBackInfo,
    TransactionsTable,
    TopHoldersTable,
    HeatMap,
    CardanoSettlements,
    TopConsumersTable
  },
  data () {
    return {
      priceInterval: null,
      loadingPrice: true,
      loadingHistoricPrice: true,
      loadingTransactions: true,
      loadingTopHolders: true,
      tokenPrice: null,
      tokenHistoricPrice: {},
      loading: true,
      vaultUsers: {},
      vaultStakingAddresses: {},
      vaultStakingBalances: {},
      vaultEarthNodes: {},
      vaultAddressesWithEarthNode: {},
      vaultAvgEarthNodesPerWallet: {},
      vaultRewards: {},
      supplyCirculating: {},
      supplyAddresses: {},
      supplyWallets: {},
      circulatingPercentageSeries: {},
      circulatingSupplyBars: {},
      lastVaultEarthNodes: '',
      lastMaxSupply: '',
      lastTotalSupply: '',
      currentMonth: '',
      totalCirculatingSupply: '',
      stakedGlobaly: '',
      distributedRewards: '',
      buyBackBalance: 0,
      buyBackAddress: ''
    }
  },
  computed: {
    featureFlags () {
      return {
        /*
          * TODO: When creating a new component card register a new ENV VAR, add it here and add a v-if to the Component
        */
        stats: (process.env.VUE_APP_WMTSCAN_STATS && JSON.parse(process.env.VUE_APP_WMTSCAN_STATS)) || false,
        map: (process.env.VUE_APP_WMTSCAN_MAP && JSON.parse(process.env.VUE_APP_WMTSCAN_MAP)) || false,
        supply: (process.env.VUE_APP_WMTSCAN_SUPPLY && JSON.parse(process.env.VUE_APP_WMTSCAN_SUPPLY)) || false,
        circulating: (process.env.VUE_APP_WMTSCAN_CIRCULATING_WMT && JSON.parse(process.env.VUE_APP_WMTSCAN_CIRCULATING_WMT)) || false,
        vaults: (process.env.VUE_APP_WMTSCAN_VAULTS_COUNT && JSON.parse(process.env.VUE_APP_WMTSCAN_VAULTS_COUNT)) || false,
        wallets: (process.env.VUE_APP_WMTSCAN_WALLETS_COUNT && JSON.parse(process.env.VUE_APP_WMTSCAN_WALLETS_COUNT)) || false,
        globalStake: (process.env.VUE_APP_WMTSCAN_GLOBAL_STAKE && JSON.parse(process.env.VUE_APP_WMTSCAN_GLOBAL_STAKE)) || false,
        staking: (process.env.VUE_APP_WMTSCAN_STAKING && JSON.parse(process.env.VUE_APP_WMTSCAN_STAKING)) || false,
        rewards: (process.env.VUE_APP_WMTSCAN_REWARDS && JSON.parse(process.env.VUE_APP_WMTSCAN_REWARDS)) || false,
        transactions: (process.env.VUE_APP_WMTSCAN_TRANSACTIONS_TABLE && JSON.parse(process.env.VUE_APP_WMTSCAN_TRANSACTIONS_TABLE)) || false,
        holders: (process.env.VUE_APP_WMTSCAN_HOLDERS_TABLE && JSON.parse(process.env.VUE_APP_WMTSCAN_HOLDERS_TABLE)) || false,
        settlements: (process.env.VUE_APP_WMTSCAN_IPDRS_SETTLEMENT_TABLE && JSON.parse(process.env.VUE_APP_WMTSCAN_IPDRS_SETTLEMENT_TABLE)) || false
      }
    }
  },
  methods: {
    getLastValue (object) {
      const values = Object.values(object)
      if (values.length === 0) {
        return 0
      }
      return [...values].pop()
    },
    async changeDays (days) {
      await Promise.all([this.loadDashboard(days), this.loadHistoricPrice(days)])
    },
    async loadDashboard (days) {
      this.loading = true

      const vaultUsers = {}
      const vaultStakingAddresses = {}
      const vaultStakingBalances = {}
      const vaultEarthNodes = {}
      const vaultAddressesWithEarthNode = {}
      const vaultAvgEarthNodesPerWallet = {}
      const vaultRewards = {}
      const supplyCirculating = {}
      const supplyTotal = {}
      const supplyAddresses = {}
      const supplyWallets = {}
      let lastVaultEarthNodes = ''
      let lastMaxSupply = 0
      let lastTotalSupply = 0
      let lastCirculatingSupply = 0

      let dashboardData
      try {
        dashboardData = await this.dashboardRepository.get(days)
      } catch (ex) {
        this.$toast.show('Error loading data, please, try again later')
        this.loading = false
        return
      }

      dashboardData.vault.map(el => {
        const date = el.date
        vaultUsers[date] = el.users
        vaultStakingAddresses[date] = el.stakingAddresses
        vaultStakingBalances[date] = el.stakingBalance
        vaultEarthNodes[date] = el.earthNodes
        vaultAddressesWithEarthNode[date] = el.addressesWithEarthNode
        vaultAvgEarthNodesPerWallet[date] = el.avgEarthNodesPerWallet
        vaultRewards[date] = el.rewards
        lastVaultEarthNodes = el.earthNodes
      })
      dashboardData.supply.map(el => {
        const date = el.date
        supplyCirculating[date] = el.circulating
        supplyTotal[date] = el.total
        supplyAddresses[date] = el.addresses
        supplyWallets[date] = el.wallets
        lastMaxSupply = el.max
        lastTotalSupply = el.total
        lastCirculatingSupply = el.circulating
      })

      this.vaultUsers = vaultUsers
      this.vaultStakingAddresses = vaultStakingAddresses
      this.vaultStakingBalances = vaultStakingBalances
      this.vaultEarthNodes = vaultEarthNodes
      this.vaultAddressesWithEarthNode = vaultAddressesWithEarthNode
      this.vaultAvgEarthNodesPerWallet = vaultAvgEarthNodesPerWallet
      this.vaultRewards = vaultRewards
      this.supplyCirculating = supplyCirculating
      this.supplyAddresses = supplyAddresses
      this.supplyWallets = supplyWallets
      this.lastMaxSupply = lastMaxSupply
      this.lastTotalSupply = lastTotalSupply
      this.lastVaultEarthNodes = lastVaultEarthNodes

      this.circulatingPercentageSeries = {
        Circulating: lastCirculatingSupply,
        'Non Circulating': lastTotalSupply - lastCirculatingSupply
      }

      this.circulatingSupplyBars = {
        Circulating: this.supplyCirculating,
        Total: supplyTotal
      }

      this.totalCirculatingSupply = Math.round(this.getLastValue(this.circulatingSupplyBars.Circulating))
      this.stakedGlobaly = Math.round(this.getLastValue(this.vaultStakingBalances))
      this.distributedRewards = Math.round(this.getLastValue(this.vaultRewards))

      this.currentMonth = moment.utc().format('MMMM YYYY')

      this.loading = false
    },
    async loadBuyBack () {
      try {
        const data = await this.holderRepository.getBuyback()
        this.buyBackAddress = data.address
        this.buyBackBalance = data.balance
      } catch (ex) {
        this.$toast.show('Error loading buyback data, please, try again later')
      }
    },
    async loadPrice () {
      this.loadingPrice = true
      try {
        this.tokenPrice = await this.priceRepository.getPrice()
        this.$store.commit('changeLastUpdate', moment.utc())
      } catch (ex) {
        this.$toast.show('Error loading data, please, try again later')
        this.loadingPrice = false
        return
      }
      this.loadingPrice = false
    },
    async loadHistoricPrice (days) {
      this.loadingHistoricPrice = true
      try {
        this.tokenHistoricPrice = this.convertPricesToSeries(
          await this.priceRepository.getHistoricPrice(days)
        )
      } catch (ex) {
        this.$toast.show('Error loading data, please, try again later')
        this.loadingHistoricPrice = false
        return
      }
      this.loadingHistoricPrice = false
    },
    async loadAll (days) {
      await Promise.all([this.loadDashboard(days), this.loadPrice(), this.loadHistoricPrice(days), this.loadBuyBack()])
    },
    clearPriceInterval () {
      if (this.priceInterval) {
        clearInterval(this.priceInterval)
      }
    },
    convertPricesToSeries (prices) {
      const ret = {}
      prices.map(p => { ret[p.time.format('YYYY-MM-DD')] = p.value })

      return ret
    },
    thousandSeparator: (value, char) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, char)
    },
    thousandSeparatorBuyBack (value, char = ',') {
      const int = value.toString().split('.')[0]
      const dec = value.toString().split('.')[1] || '000000'
      return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, char) + '.' + dec
    },
    compactString (value) {
      const formatter = new FormatterService()
      return formatter.compactString(value)
    }

  },
  mounted () {
    this.loadAll(this.$store.state.days)
    this.clearPriceInterval()
    this.priceInterval = setInterval(this.loadPrice, 300000)
  },
  beforeUnmount () {
    this.clearPriceInterval()
  },
  filters: {
    thousandSeparator: (value, char) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, char)
    }
  },
  watch: {
    '$store.state.days': function () {
      this.changeDays(this.$store.state.days)
    },
    loading: function () {
      this.$store.commit('setLoading', { name: 'loading', value: this.loading })
    },
    loadingHistoricPrice: function () {
      this.$store.commit('setLoading', { name: 'loadingHistoricPrice', value: this.loadingHistoricPrice })
    }
  }
}
</script>
