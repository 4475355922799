<template>
  <chart-card :title="title" :loading="loading">
    <div class="mt-4 pa-4">
      <vue-apex-charts type="pie" height="350" :options="options" :series="compSeries" />
    </div>
  </chart-card>
</template>

<script>
import { background, baseColors, graphColors } from '@/assets/colors.js'
import VueApexCharts from 'vue-apexcharts'
import ChartCard from '../Card'

export default {
  components: { ChartCard, VueApexCharts },
  props: {
    title: {
      type: String,
      required: true
    },
    series: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onClick (event, _this) {
      if (_this.$cookies.list['google-analytics']) {
        if (event.target.classList.contains('apexcharts-menu-item')) {
          let type = ''
          const days = _this.$store.state.days + ' ' + 'days'

          if (event.target.classList.contains('exportSVG')) {
            type = 'SVG'
          } else if (event.target.classList.contains('exportPNG')) {
            type = 'PNG'
          } else if (event.target.classList.contains('exportCSV')) {
            type = 'CSV'
          }

          _this.$gtag.event('Download chart (by period)', {
            event_category: 'Chart',
            event_label: days
          })

          _this.$gtag.event('Download chart (by name)', {
            event_category: 'Chart',
            event_label: _this.title
          })

          _this.$gtag.event('Download chart (by type)', {
            event_category: 'Chart',
            event_label: type
          })

          _this.$gtag.event('Download chart (count)', {
            event_category: 'Chart',
            event_label: 'total'
          })

          _this.$gtag.event('Download chart (by type and period)', {
            event_category: 'Chart',
            event_label: type + ' ' + days
          })

          _this.$gtag.event('Download chart (by name and period)', {
            event_category: 'Chart',
            event_label: _this.title + ' ' + days
          })

          _this.$gtag.event('Download chart (by name and type)', {
            event_category: 'Chart',
            event_label: _this.title + ' ' + type
          })

          _this.$gtag.event('Download chart (by name, type and period)', {
            event_category: 'Chart',
            event_label: _this.title + ' ' + type + ' ' + days
          })
        }
      }
    }
  },
  computed: {
    compSeries () {
      return this.series ? Object.values(this.series) : []
    },
    options () {
      return {
        colors: graphColors,
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return val.toLocaleString('en-US', { maximumFractionDigits: 6 })
            }
          }
        },
        chart: {

          foreColor: this.$vuetify.theme.dark ? baseColors.white : baseColors.black,
          background: this.$vuetify.theme.dark ? background.dark.darken1 : background.light.darken1,
          height: 350,
          zoom: {
            enabled: false
          },

          toolbar: {
            show: true,
            download: true
          },
          events: {
            click: (event) => this.onClick(event, this)
          }
        },
        total: {
          show: false
        },
        plotOptions: {
          pie: {
            offsetX: 15
          }

        },
        labels: this.series ? Object.keys(this.series) : [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .apex-chart-card .apexcharts-canvas {
    @media (max-width: 480px) {
      /* override inline-sytle 200px */
      width: 100% !important;
      /* */

      /* Better centering */
      .apexcharts-svg {
        min-width: 75%;
        margin-left: auto;
        margin-right: auto;
        display: block;

        foreignObject {
          width: 100%;
        }
      }
    }
  }
</style>
